import NodeData from './NodeData';
import { createEdge } from './Edge';
import { createConditionEdge } from './ConditionEdge';
import { API_URL, authenticatedFetch } from './config';

export const saveJson = async (nodes, nodeIdCounter, scenarioName) => {
  const nodesData = nodes.map((node) => NodeData.fromReactFlowNode(node));

  const scenarioData = {
    name: scenarioName.replace(".json", ""),  // Изменено с scenario_id на name
    content: {  // Изменено с scenario_data на content
      nodes: nodesData.map((node) => node.toDict()),
      node_counter: nodeIdCounter,
    },
  };

  console.log('Sending scenario data:', scenarioData);  // Добавьте эту строку для отладки

  try {
    const response = await authenticatedFetch('/save', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(scenarioData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Server response:', errorData);  // Добавьте эту строку для отладки
      throw new Error('Failed to save scenario: ' + (errorData.error || 'Unknown error'));
    }

    return await response.json();

  } catch (error) {
    console.error('Error saving scenario:', error);
    throw error;
  }
};

// New function to save stages
export const saveStages = async (scenarioName, stages) => {
  if (!scenarioName) {
    throw new Error('Scenario name is required to save stages.');
  }

  if (!Array.isArray(stages)) {
    throw new Error('Stages must be an array.');
  }

  try {
    const response = await authenticatedFetch(`/scenarios/${encodeURIComponent(scenarioName)}/stages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ stages }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Server response:', errorData);
      throw new Error('Failed to save stages: ' + (errorData.error || 'Unknown error'));
    }

    return await response.json();

  } catch (error) {
    console.error('Error saving stages:', error);
    throw error;
  }
};

export const loadJson = (flowData, setEdges, setNodes, setNodeIdCounter) => {
  if (!flowData || typeof flowData !== 'object' || !flowData.nodes || !Array.isArray(flowData.nodes)) {
    console.error("Invalid or missing data:", flowData);
    return;
  }

  // Преобразование узлов из JSON в формат, понятный ReactFlow
  const loadedNodes = flowData.nodes.map((nodeData) => NodeData.fromDict(nodeData).toReactFlowNode());

  setNodes(loadedNodes);

  const loadedEdges = [];
  loadedNodes.forEach((node) => {
    node.data.nexts.forEach((nextId) => {
      const newEdge = createEdge(loadedEdges, setEdges, { source: node.id, target: nextId }, loadedNodes, setNodes);
      if (newEdge) {
        loadedEdges.push(newEdge);
      }
    });

    Object.keys(node.data.paths || {}).forEach((pathKey) => {
      const targetId = node.data.paths[pathKey];
      if (targetId) {
        const newEdge = createConditionEdge(loadedEdges, setEdges, { source: node.id, target: targetId, sourceHandle: pathKey }, loadedNodes, setNodes);
        if (newEdge) {
          loadedEdges.push(newEdge);
        }
      }
    });
  });

  setEdges(loadedEdges);

  setNodeIdCounter(flowData.node_counter || 1);
};