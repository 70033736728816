import React, { useState, useEffect, useCallback, useContext, createContext } from 'react';
import ReactFlow, { Background, useEdgesState, useReactFlow } from 'reactflow';
import { useNavigate } from 'react-router-dom';
import 'reactflow/dist/style.css';
import { FaPlus, FaTrash, FaSave, FaPlay, FaPalette, FaFolderOpen, FaComments, FaSignOutAlt } from 'react-icons/fa';
import Node, { addNode, deleteNode } from './Node';
import { createEdge, deleteEdge } from './Edge';
import { createConditionEdge, deleteConditionEdge } from './ConditionEdge';
import { saveJson, loadJson, saveStages } from './JsonUtils'; // Import saveStages
import { useGraphManager, GraphManagerProvider } from './GraphManagerContext';
import ConfigWindow from './ConfigWindow';
import RunWindow from './RunWindow';
import logo from './Union.svg'; 
import { API_URL } from './config';
import FileTransmit from './FileTransmit';

const nodeTypes = { textUpdater: Node };

const ThemeContext = createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

function ThemeProvider({ children }) {
  const [darkMode, setDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme === 'dark' : true;
  });
  const [currentTheme, setCurrentTheme] = useState(() => {
    return localStorage.getItem('theme') || 'cmyk';
  });

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', currentTheme);
    localStorage.setItem('theme', currentTheme);
    setDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode, currentTheme, setCurrentTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

function Header({ onLogout }) {
  return (
    <header className="fixed w-[20%] top-4 left-1/4  transform -translate-x-[340px] z-20 flex items-center p-4 bg-base-100 text-base-content rounded-lg shadow-lg justify-left">
      <img src={logo} alt="Logo" className="h-6 justify-left items-left" />
      <button 
        onClick={onLogout}
        className="absolute right-4 btn bg-transparent hover:bg-base-200 border-none transition-colors duration-200"
      >
        <FaSignOutAlt size={15} />
      </button>
    </header>
  );
}

function ThemeSelector({ onThemeChange }) {
  const { setDarkMode } = useTheme();
  const themes = [
    "light", "dark", "cupcake", "bumblebee", "emerald", "corporate",
    "synthwave", "retro", "cyberpunk", "valentine", "halloween", "garden",
    "forest", "aqua", "lofi", "pastel", "fantasy", "wireframe", "black",
    "luxury", "dracula", "cmyk", "autumn", "business", "acid", "lemonade",
    "night", "coffee", "winter", "dim", "nord", "sunset"
  ];

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-base-100 p-6 rounded-lg shadow-lg w-80">
        <h2 className="text-xl mb-4">Выберите стиль</h2>
        <div className="grid grid-cols-2 gap-2 max-h-60 overflow-y-auto">
          {themes.map((theme) => (
            <button
              key={theme}
              className="btn btn-outline w-full"
              onClick={() => {
                document.documentElement.setAttribute('data-theme', theme);
                setDarkMode(theme === 'dark');
                onThemeChange(false);
              }}
            >
              {theme}
            </button>
          ))}
        </div>
        <div className="mt-4 text-right">
          <button className="btn" onClick={() => onThemeChange(false)}>Закрыть</button>
        </div>
      </div>
    </div>
  );
}

function Canvas({ onLogout, isAuthenticated }) {
  const navigate = useNavigate();

  const handleNew = () => {
    setShowNewScenarioConfirm(true);
  };

  const [showNewScenarioConfirm, setShowNewScenarioConfirm] = useState(false);

  // Modify handleNew to show confirmation first
  

  // Add new function to handle actual creation
  const confirmNew = () => {
    setNodes([]);
    setEdges([]);
    setNodeIdCounter(1);
    setSelectedScenario('');
    setShowNewScenarioConfirm(false);
  };

  const handleLogout = () => {
    navigate('/');
    localStorage.removeItem('authToken');
    window.location.reload();
  };

  const {
    nodes,
    setNodes,
    onNodesChange,
    nodeIdCounter,
    setNodeIdCounter,
  } = useGraphManager();

  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const { screenToFlowPosition } = useReactFlow();
  const [canvasHeight, setCanvasHeight] = useState(window.innerHeight);
  const [showConfig, setShowConfig] = useState(false);
  const [showRun, setShowRun] = useState(false);
  const [isScenarioModalOpen, setScenarioModalOpen] = useState(false);
  const [isAlertModalOpen, setAlertModalOpen] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [scenarioToDelete, setScenarioToDelete] = useState(null);
  const { darkMode, setDarkMode } = useTheme();

  const [scenarios, setScenarios] = useState([]);
  const [selectedScenario, setSelectedScenario] = useState('');
  const [newScenarioName, setNewScenarioName] = useState('');
  const [newScenarioStages, setNewScenarioStages] = useState(['']); // Initialize with one stage input
  const [showThemeSelector, setShowThemeSelector] = useState(false);
  const [showScenarioSelector, setShowScenarioSelector] = useState(false);
  const [isDeleteSuccessModalOpen, setDeleteSuccessModalOpen] = useState(false);


 
  

  useEffect(() => {
    const handleResize = () => {
      setCanvasHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchScenarios = useCallback(async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('No auth token found');
        return;
      }
      const response = await fetch(`${API_URL}/scenarios`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Fetched Scenarios:', data.scenarios);
        setScenarios(data.scenarios);
      } else if (response.status === 401) {
        console.error('Unauthorized: Token may be invalid or expired');
        // Here you can add logic to redirect to the login page
      } else {
        console.error('Error fetching scenarios');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }, []);

  useEffect(() => {
    fetchScenarios();
  }, [fetchScenarios]);

  useEffect(() => {
    if (selectedScenario && selectedScenario.data) {
      const scenarioData = selectedScenario.data;
      if (typeof scenarioData === 'string' || (typeof scenarioData === 'object' && scenarioData.nodes)) {
        console.log("Selected scenario:", selectedScenario);
        loadJson(scenarioData, setEdges, setNodes, setNodeIdCounter);
      } else {
        console.error("Unexpected data structure:", scenarioData);
      }
    }
  }, [selectedScenario, setEdges, setNodes, setNodeIdCounter]);

  const handleAddNode = useCallback((event) => {
    const newPosition = screenToFlowPosition({ x: contextMenu.mouseX, y: contextMenu.mouseY });
    addNode(nodes, setNodes, nodeIdCounter, setNodeIdCounter, newPosition);
    setContextMenu(null);
  }, [contextMenu, nodeIdCounter, setNodes, screenToFlowPosition, nodes, setNodeIdCounter]);

  const handleDeleteNode = useCallback(() => {
    if (contextMenu && contextMenu.nodeId) {
      deleteNode(nodes, setNodes, edges, setEdges, contextMenu.nodeId);
    }
    setContextMenu(null);
  }, [contextMenu, setNodes, setEdges, nodes, edges]);

  const handleDeleteEdge = useCallback(() => {
    if (contextMenu && contextMenu.edgeId) {
      const edge = edges.find((e) => e.id === contextMenu.edgeId);
      if (edge) {
        if (edge.sourceHandle === 'true' || edge.sourceHandle === 'false' || edge.sourceHandle) {
          deleteConditionEdge(edges, setEdges, contextMenu.edgeId, nodes, setNodes);
        } else {
          deleteEdge(edges, setEdges, contextMenu.edgeId, nodes, setNodes);
        }
      }
    }
    setContextMenu(null);
  }, [contextMenu, setEdges, edges, nodes, setNodes]);

  const handleNodeContextMenu = useCallback((event, node) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      nodeId: node.id,
      isNode: true,
      isEdge: false,
    });
  }, []);

  const handlePaneContextMenu = useCallback((event) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      isNode: false,
      isEdge: false,
    });
  }, []);

  const handleEdgeContextMenu = useCallback((event, edge) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      edgeId: edge.id,
      isNode: false,
      isEdge: true,
    });
  }, []);

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const onConnect = useCallback((params) => {
    const sourceNode = nodes.find(node => node.id === params.source);
  
    if (sourceNode && sourceNode.data.type === 'CONDITION') {
      createConditionEdge(edges, setEdges, params, nodes, setNodes);
    } else {
      createEdge(edges, setEdges, params, nodes, setNodes);
    }
  }, [setEdges, edges, nodes, setNodes]);  

  

  const handleSave = async () => {
    console.log("handleSave called");
    // Проверяем все возможные варианты имени сценария
    let scenarioName = selectedScenario?.file_name || selectedScenario?.name || '';
    console.log("Selected scenario object:", selectedScenario);
    console.log("Selected scenario name:", scenarioName);
  
    if (!scenarioName) {
      console.log("No scenario name, opening creation modal");
      setScenarioModalOpen(true);
    } else {
      console.log("Attempting to save existing scenario");
      try {
        // Убедимся, что имя файла заканчивается на .json
        if (!scenarioName.endsWith('.json')) {
          scenarioName += '.json';
        }
        
        // Сохраняем существующий сценарий
        await saveJson(nodes, nodeIdCounter, scenarioName);
        console.log("Scenario content saved");
        
        // Получаем имя без расширения .json для сохранения этапов
        const scenarioNameWithoutExt = scenarioName.replace('.json', '');
        await saveStages(scenarioNameWithoutExt, newScenarioStages);
        console.log("Stages saved");
        
        setSuccessModalOpen(true);
        fetchScenarios();
        console.log("Save completed successfully");
      } catch (error) {
        console.error('Error saving scenario with stages:', error);
        alert('Failed to save scenario. Please try again.');
      }
    }
  };

  const handleScenarioSave = async () => {
    if (!newScenarioName.trim()) return;

    // Validate stages
    const filteredStages = newScenarioStages.map(stage => stage.trim()).filter(stage => stage !== '');
    if (filteredStages.length === 0) {
      alert('Please add at least one stage.');
      return;
    }

    const scenarioName = newScenarioName.trim().replace(/\s+/g, "_") + ".json";

    try {
      // Save the scenario content
      await saveJson(nodes, nodeIdCounter, scenarioName);
      // Save the stages
      await saveStages(scenarioName.replace('.json', ''), filteredStages);

      setScenarioModalOpen(false);
      setSuccessModalOpen(true);
      fetchScenarios();
    } catch (error) {
      console.error('Error saving scenario with stages:', error);
      alert('An error occurred while saving the scenario.');
    }
  };

  const handleDeleteIconClick = (scenario) => {
    setScenarioToDelete(scenario);
    setDeleteModalOpen(true);
    setShowScenarioSelector(false); // Закрываем модальное окно выбора сценария
  };

  const confirmDeleteScenario = async () => {
    if (scenarioToDelete && scenarioToDelete.name) {
      const scenarioName = scenarioToDelete.name.replace(/\.json$/, '');
  
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch(`${API_URL}/scenarios/${encodeURIComponent(scenarioName)}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
  
        if (response.ok) {
          setNodes([]);
          setEdges([]);
          setNodeIdCounter(1);
          setSelectedScenario('');
  
          fetchScenarios();
          setDeleteModalOpen(false);
          setDeleteSuccessModalOpen(true); // Открываем модальное окно успешного удаления
        } else {
          const errorData = await response.json();
          console.error('Ответ сервера:', errorData);
          alert(`Ошибка при удалении сценария: ${errorData.error || 'Неизвестная ошибка'}`);
        }
      } catch (error) {
        console.error('Ошибка при удалении сценария:', error);
        alert('Ошибка при удалении сценария');
      }
    } else {
      console.error('Сценарий для удаления не выбран или его имя отсутствует');
      alert('Ошибка при удалении сценария');
      setDeleteModalOpen(false);
    }
  };

  const handleRun = () => {
    if (!selectedScenario) {
      setAlertModalOpen(true);
      return;
    }
    setShowRun(true);
  };

  const getEdgeColor = () => {
    return darkMode ? '#FFFFFF' : '#000000';
  };



 

  const handleSelectScenario = async (scenario) => {
    console.log("Selected scenario data:", scenario); // Добавляем лог
    setSelectedScenario(scenario);
    setShowScenarioSelector(false);
  
    try {
      // Fetch stages for the selected scenario
      const scenarioName = scenario.name || scenario.file_name;
      if (!scenarioName) {
        console.error("No scenario name found");
        return;
      }
  
      const response = await fetch(`${API_URL}/scenarios/${encodeURIComponent(scenarioName)}/stages`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        console.log('Fetched Stages:', data.stages);
        setNewScenarioStages(data.stages || []); // Добавляем fallback на пустой массив
      } else {
        console.error('Error fetching stages for the scenario');
      }
    } catch (error) {
      console.error('Error in handleSelectScenario:', error);
    }
  };

  // Handlers for managing stages in the scenario creation modal
  const handleStageChange = (index, value) => {
    const updatedStages = [...newScenarioStages];
    updatedStages[index] = value;
    setNewScenarioStages(updatedStages);
  };

  const addStageInput = () => {
    setNewScenarioStages([...newScenarioStages, '']);
  };

  const removeStageInput = (index) => {
    const updatedStages = newScenarioStages.filter((_, i) => i !== index);
    setNewScenarioStages(updatedStages);
  };

  return (
    <div className={`h-screen w-full relative ${darkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      {/* Header */}
      <Header onLogout={handleLogout} />

      <div className="flex flex-1">
        {/* Sidebar */}
        <aside className={`fixed top-1/2 left-6 transform -translate-y-1/2 w-16 p-4 bg-base-100 text-base-content flex flex-col items-center space-y-4 rounded-lg shadow-lg z-20 transition-transform hover:-translate-x-1`}>
          <button onClick={handleNew} className="hover:bg-base-300 rounded-full p-2 tooltip  tooltip-right" data-tip="Новый сценарий">
            <FaPlus/>
          </button>
          <button onClick={() => setShowScenarioSelector(true)} className="hover:bg-base-300 rounded-full p-2 tooltip  tooltip-right" data-tip="Выбрать сценарий">
            <FaFolderOpen />
          </button>
          <button onClick={handleRun} className="hover:bg-base-300 rounded-full p-2 tooltip  tooltip-right" data-tip="Запустить сценарий">
            <FaPlay />
          </button>
          <button onClick={handleSave} className="hover:bg-base-300 rounded-full p-2 tooltip  tooltip-right" data-tip="Сохранить сценарий">
            <FaSave />
          </button>
          <FileTransmit onUploadComplete={fetchScenarios} />
          <button onClick={() => setShowThemeSelector(true)} className="hover:bg-base-300 rounded-full p-2 tooltip  tooltip-right" data-tip="Выбрать стиль">
            <FaPalette />
          </button>
        </aside>
        
        {/* Main Graph Area */}
        <main className="flex-1 h-full">
          <div style={{ height: `${canvasHeight}px` }} className="w-full">
            <ReactFlow 
              nodes={nodes.map(node => ({ 
                ...node, 
                data: { 
                  ...node.data, 
                  theme: darkMode ? 'dark' : 'light', 
                  selectedScenario: selectedScenario  
                } 
              }))} 
              edges={edges.map(edge => ({
                ...edge,
                style: {
                  stroke: getEdgeColor(),
                  strokeWidth: 2,
                },
              }))}
              onNodesChange={onNodesChange} 
              onEdgesChange={onEdgesChange}
              onNodeContextMenu={handleNodeContextMenu}
              onPaneContextMenu={handlePaneContextMenu}
              onEdgeContextMenu={handleEdgeContextMenu}
              onClick={handleCloseContextMenu}
              onConnect={onConnect}
              connectionLineStyle={{ stroke: getEdgeColor(), strokeWidth: 2 }}
              connectOnClick={false}
              nodeTypes={nodeTypes}
              minZoom={0.2}
              maxZoom={2}
              fitView
            >
              <Background 
                color={getEdgeColor()} 
                gap={16} 
                size={1} 
                variant="dots"
                style={{ backgroundColor: darkMode ? '#1a1a1a' : '#f0f0f0' }}
              />
            </ReactFlow>
          </div>

          {/* RunWindow Panel */}
          {showRun && selectedScenario && (
            <div className="fixed top-24 mr-10 right-0 w-100 h-3/4 bg-base-100 shadow-lg z-30 flex flex-col rounded-lg">
              <RunWindow
                onClose={() => setShowRun(false)}
                scenarioId={selectedScenario.name || `scenario_${selectedScenario.id}`}
              />
            </div>
          )}

          {contextMenu && (
            <div
              className={`absolute z-30 rounded ${darkMode ? 'bg-gray-800 border-gray-600' : 'bg-white border-gray-300'} context-menu`}
              style={{
                top: contextMenu.mouseY,
                left: contextMenu.mouseX,
              }}
            >
              {contextMenu.isNode ? (
                <button onClick={handleDeleteNode} className={`btn btn-error ${darkMode ? 'text-white' : 'text-white'}`}>
                  <FaTrash />
                </button>
              ) : contextMenu.isEdge ? (
                <button onClick={handleDeleteEdge} className={`btn btn-error ${darkMode ? 'text-white' : 'text-white'}`}>
                  <FaTrash />
                </button>
              ) : (
                <button onClick={handleAddNode} className={`btn btn-success ${darkMode ? 'text-white' : 'text-white'}`}>
                  <FaPlus />
                </button>
              )}
            </div>
          )}

          {showConfig && <ConfigWindow onClose={() => setShowConfig(false)} />}
        </main>
      </div>

      {showThemeSelector && <ThemeSelector onThemeChange={setShowThemeSelector} />}

      {/* Modals */}
     {/* Scenario Creation/Edit Modal */}
     <div className={`modal ${isScenarioModalOpen ? 'modal-open' : ''}`}>
        <div className="modal-box">
          <h2 className="text-lg mb-4">
            {selectedScenario ? 'Редактирование сценария' : 'Создание нового сценария'}
          </h2>
          
          {!selectedScenario && (
            <input
              type="text"
              className="input input-bordered w-full mb-4"
              value={newScenarioName}
              onChange={(e) => setNewScenarioName(e.target.value)}
              placeholder="Имя сценария"
            />
          )}

          {selectedScenario && (
            <div className="mb-4">
              <label className="label">
                <span className="label-text">Название сценария</span>
              </label>
              <div className="input input-bordered w-full bg-base-200 flex items-center px-4 py-2">
                {selectedScenario.file_name?.replace('.json', '') || selectedScenario.name?.replace('.json', '')}
              </div>
            </div>
          )}
          <h3 className="text-md mb-2">Этапы сценария</h3>
          {newScenarioStages.map((stage, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                className="input input-bordered flex-1"
                value={stage}
                onChange={(e) => handleStageChange(index, e.target.value)}
                placeholder={`Этап ${index + 1}`}
              />
              {newScenarioStages.length > 1 && (
                <button
                  onClick={() => removeStageInput(index)}
                  className="btn btn-error ml-2"
                  title="Удалить этап"
                >
                  <FaTrash />
                </button>
              )}
            </div>
          ))}
          <button onClick={addStageInput} className="btn btn-secondary w-full mb-4">
            Добавить этап
          </button>
          <div className="modal-action">
            <button className="btn" onClick={() => setScenarioModalOpen(false)}>Отмена</button>
            <button 
              className="btn btn-primary" 
              onClick={selectedScenario ? handleSave : handleScenarioSave}
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>

      {/* Alert Modal */}
      <div className={`modal ${isAlertModalOpen ? 'modal-open' : ''}`}>
        <div className="modal-box">
          <h2 className="text-lg mb-4">Ошибка</h2>
          <p>Пожалуйста, выберите сценарий перед запуском.</p>
          <div className="modal-action">
            <button className="btn btn-primary" onClick={() => setAlertModalOpen(false)}>ОК</button>
          </div>
        </div>
      </div>

      {/* Success Modal */}
      <div className={`modal ${isSuccessModalOpen ? 'modal-open' : ''}`}>
        <div className="modal-box">
          <h2 className="text-lg mb-4">Сценарий сохранен</h2>
          <p>Сценарий был успешно сохранен.</p>
          <div className="modal-action">
            <button className="btn btn-primary" onClick={() => setSuccessModalOpen(false)}>ОК</button>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <div className={`modal ${isDeleteModalOpen ? 'modal-open' : ''}`}>
      <div className="modal-box">
        <h2 className="text-lg mb-4">Подтверждение удаления</h2>
        <p>Вы уверены, что хотите удалить сценарий "{scenarioToDelete?.name.replace('.json', '') || 'Безымянный сценарий'}"?</p>
        <div className="modal-action">
          <button className="btn" onClick={() => setDeleteModalOpen(false)}>Отмена</button>
          <button className="btn btn-error" onClick={confirmDeleteScenario}>Удалить</button>
        </div>
      </div>
    </div>

     {/* Add New Scenario Confirmation Modal */}
     <div className={`modal ${showNewScenarioConfirm ? 'modal-open' : ''}`}>
        <div className="modal-box">
          <h2 className="text-lg mb-4">Создать новый сценарий?</h2>
          <p>Все несохраненные изменения будут утеряны. Вы уверены?</p>
          <div className="modal-action">
            <button className="btn" onClick={() => setShowNewScenarioConfirm(false)}>Отмена</button>
            <button className="btn btn-primary" onClick={confirmNew}>Создать</button>
          </div>
        </div>
      </div>

    {/* Delete Success Modal */}
<div className={`modal ${isDeleteSuccessModalOpen ? 'modal-open' : ''}`}>
  <div className="modal-box">
    <h2 className="text-lg mb-4">Сценарий удален</h2>
    <p>Сценарий был успешно удален.</p>
    <div className="modal-action">
      <button className="btn btn-primary" onClick={() => setDeleteSuccessModalOpen(false)}>ОК</button>
    </div>
        </div>
      </div>

      {/* Floating Chat Button */}
      <button
        onClick={handleRun}
        className="fixed bottom-6 right-6 bg-primary hover:bg-primary-focus text-white rounded-full p-4 shadow-lg z-40 hover:bg-secondary transition-colors duration-300  "
        title="Открыть чатбот"
      >
        <FaComments size={24} />
      </button>

      {/* Scenario Selector Modal */}
      {showScenarioSelector && (
  <div className="modal modal-open">
    <div className="modal-box">
      <h3 className="font-bold text-lg mb-4">Выберите сценарий</h3>
      <div className="flex flex-col space-y-2">
      {scenarios.map((scenario) => (
  <div key={scenario.id || scenario.name} className="flex flex-col bg-base-200 p-2 rounded">
    <div className="flex justify-between items-center">
      <span>{scenario.name || (scenario.file_name && scenario.file_name.replace('.json', '')) || 'Безымянный сценарий'}</span>
      <div className="flex space-x-2">
      {selectedScenario?.id === scenario.id ? (
  <span className="px-2 py-1 rounded-md bg-success/20 text-success text-sm font-medium flex items-center gap-1">
    <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
    </svg>
    Активный
  </span>
) : (
  <button
    onClick={() => handleSelectScenario(scenario)}
    className="btn btn-secondary btn-sm"
  >
    Выбрать
  </button>
)}
        <button
          onClick={() => handleDeleteIconClick(scenario)}
          className="btn btn-error btn-sm"
        >
          <FaTrash />
        </button>
      </div>
    </div>
    {/* Display stages */}
    <div className="mt-3 bg-base-100 rounded-lg p-3">
  <h4 className="text-sm font-semibold flex items-center gap-2 mb-2">
    <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
    </svg>
    Этапы сценария
  </h4>
  <ul className="space-y-1.5">
    {scenario.stages && scenario.stages.map((stage, index) => (
      <li key={index} className="flex items-center gap-2 text-sm">
        <span className="flex-shrink-0 w-5 h-5 rounded-full bg-primary/20 text-primary flex items-center justify-center text-xs font-medium">
          {index + 1}
        </span>
        <span className="text-base-content/80">{stage}</span>
      </li>
    ))}
    {(!scenario.stages || scenario.stages.length === 0) && (
      <li className="text-sm text-base-content/60 italic">Этапы не определены</li>
    )}
  </ul>
</div>
  </div>
))}
        {scenarios.length === 0 && <p>Нет доступных сценариев.</p>}
      </div>
      <div className="modal-action">
        <button className="btn" onClick={() => setShowScenarioSelector(false)}>Закрыть</button>
      </div>
    </div>
  </div>
)}
    </div>
  );
}

export default () => (
  <ThemeProvider>
    <GraphManagerProvider>
      <Canvas />
    </GraphManagerProvider>
  </ThemeProvider>
);