import React, { useState, useEffect } from 'react';
import { API_URL } from './config';
import bubblesImage from './bubbles.jpg';
import bubblesBgImage from './bubblesbg.jpg';
import logo from './Union.svg';

function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const savedEmail = localStorage.getItem('rememberedEmail');
    if (savedEmail) {
      setEmail(savedEmail);
      setRememberMe(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      if (response.ok) {
        const data = await response.json();
        if (rememberMe) {
          localStorage.setItem('rememberedEmail', email);
        } else {
          localStorage.removeItem('rememberedEmail');
        }
        localStorage.setItem('authToken', data.token);
        onLogin(data.token);
      } else {
        const data = await response.json();
        setError(data.error || 'Произошла ошибка');
        setShowModal(true);
      }
    } catch (error) {
      console.error('Ошибка:', error);
      setError('Произошла ошибка при подключении к серверу');
      setShowModal(true);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-200 to-purple-300 flex items-center justify-center p-4 relative">
      <div className="absolute inset-0 z-0 bg-cover bg-center filter blur-lg opacity-50 animate-bgMove" style={{
        backgroundImage: `url(${bubblesBgImage})`,
        backgroundSize: '200% 200%', // Increase the size to make the animation more visible
        backgroundPosition: 'center', // Center the background
      }}></div>
      <div className="card bg-white shadow-xl overflow-hidden max-w-4xl w-full flex flex-col md:flex-row relative z-10">
        <div className="card-body w-full md:w-1/2 p-6">
          <div className="flex justify-start mb-4">
            <img src={logo} alt="NIKTA GRAPH Logo" className="h-8" />
          </div>
          <h1 className="text-2xl font-bold mb-4 text-gray-900">Ваш персональный хаб GPTшек</h1>
          <p className="text-sm text-gray-600 mb-6">С возвращением! Пожалуйста, войдите в свой аккаунт, чтобы продолжить</p>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="form-control">
              <label className="label" htmlFor="email">
                <span className="label-text text-gray-700">Адрес электронной почты</span>
              </label>
              <input
                id="email"
                type="email"
                required
                className="input input-bordered bg-gray-50 text-gray-900 focus:ring-2 focus:ring-blue-300 focus:border-blue-300 transition-all duration-200"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-control">
              <label className="label" htmlFor="password">
                <span className="label-text text-gray-700">Пароль</span>
              </label>
              <input
                id="password"
                type="password"
                required
                className="input input-bordered bg-gray-50 text-gray-900 focus:ring-2 focus:ring-blue-300 focus:border-blue-300 transition-all duration-200"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-between">
              <label className="label cursor-pointer">
                <input 
                  type="checkbox" 
                  className="checkbox checkbox-info bg-white border-gray-300" 
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <span className="label-text ml-2 text-gray-700 ">Запомнить меня</span>
              </label>
              <a href="https://t.me/fron1runner" className="link link-primary text-blue-400 hover:text-blue-500 transition-colors duration-200 text-sm">Забыли пароль?</a>
            </div>
            <div className="form-control mt-6">
              <button type="submit" className="btn bg-blue-400 hover:bg-blue-500 text-white border-none transition-transform transform hover:scale-105 duration-200">ВОЙТИ</button>
            </div>
          </form>
          <p className="mt-6 text-center text-xs text-gray-600">
            Нет аккаунта? Свяжитесь с нами в Telegram{' '}
            <a href="https://t.me/fron1runner" className="link link-primary text-blue-400 hover:text-blue-500 transition-colors duration-200" target="_blank" rel="noopener noreferrer">
              @fron1runner
            </a>
          </p>
        </div>
        <div className="w-full md:w-1/2 relative overflow-hidden">
          <img
            src={bubblesImage}
            alt="Bubbles"
            className="absolute inset-0 w-full h-full object-cover"
          />
        </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ">
          <div className="modal modal-open bg-white">
            <div className="modal-box bg-white">
              <h3 className="font-bold text-lg text-red-500">Ошибка</h3>
              <p className="py-4 text-black">{error}</p>
              <div className="modal-action">
                <button className="btn btn-info text-white" onClick={() => setShowModal(false)}>
                  Закрыть
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;