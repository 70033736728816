import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Handle, Position } from 'reactflow';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FaFile, FaPlus, FaTrash, FaPlay, FaEdit, FaQuestionCircle, FaStickyNote, FaUser } from 'react-icons/fa';
import { IoCall } from "react-icons/io5";
import { TiFlowSwitch } from "react-icons/ti";
import { FaRobot } from "react-icons/fa6";
import { useTheme } from './Canvas';
import { FaGoogle, FaFileExcel } from "react-icons/fa";
import { FaBookOpen } from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import { API_URL, authenticatedFetch } from './config';
import ReactDOM from 'react-dom';

const infoTexts = {
  START: 'Стартовая точка, с нее начинаются все сценарии, без нее сценарий не работает ☝️',
  STEP: 'Точка перехода, обычно это умный ИИ агент, со своими инструментами и своим описанием поведения 🤖',
  CONDITION: 'Условие для перехода на следующий граф, создавайте разные пути, которые ведут на разных агентов/сценарии или другие условия! 🎲',
  SCENARIO: 'Вы можете создавать связь с другими сценариями, таким образом комбинируя множество сценариев, создавая сложные многоуровневые системы 📖',
  MESSAGE: 'Шаблонное сообщение, которое можно отправить без использования ИИ, можно использовать как для простой отправки конечного сообщения, так и для добавления сообщения в промпт ✉️',
  STICKER: 'Стикер для заметок на доске, не имеет соединений и точек присоединения 📌'
};

const NodeLayout = ({
  data,
  isConnectable,
  onChangeName,
  onChangeDescription,
  onChangeType,
  onChangeTool,
  onChangeVectorStore,
  onAddPath,
  onDeletePath,
  onSaveNode,
  onUpdatePathDescription,
  onChangeScenario,
  onChangeColor,
  onChangeModel
}) => {
  const [vectorStores, setVectorStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fullDescription, setFullDescription] = useState(data.description);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPathKey, setSelectedPathKey] = useState(null);
  const [pathDescription, setPathDescription] = useState('');
  const { darkMode } = useTheme();
  const [isPathModalOpen, setPathModalOpen] = useState(false);
  const [newPathName, setNewPathName] = useState('');
  const [scenarios, setScenarios] = useState([]);
  const scenarios2 = data.selectedScenario ? [data.selectedScenario] : [];

  const [isColorPaletteOpen, setColorPaletteOpen] = useState(false);

  const fetchScenarios = async () => {
    try {
      const response = await authenticatedFetch('/scenarios');
      if (response.ok) {
        const data = await response.json();
        console.log('Fetched Scenarios:', data.scenarios);
        setScenarios(data.scenarios);
      } else {
        console.error('Error fetching scenarios');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchScenarios();
  }, []);

  const toolOptions = [
    { value: 'rag', label: <span className='flex flex-row items-center'><FaFile className="text-orange-500 mr-2 text-xs" />Excel, Word, Pdf, Txt</span> },
    { value: 'operatorCall', label: <span className='flex flex-row items-center'><IoCall className="text-green-500 mr-2 text-xs" />Вызов оператора</span> },
    { value: 'internet_search', label: <span className='flex flex-row items-center'><FaGoogle className="text-blue-500 mr-2 text-xs" /> Google поиск</span> },
    { value: 'client_data', label: <span className='flex flex-row items-center'><FaUser className="text-purple-500 mr-2 text-xs" /> Данные клиента</span> },
  ];

  useEffect(() => {
    const fetchVectorStores = async () => {
      try {
        const response = await authenticatedFetch('/vectorstores');
        if (!response.ok) throw new Error('Network response was not ok');
        const stores = await response.json();
        setVectorStores(stores.vector_stores);
  
        if (data.tool.includes('rag') && (!data.vectorStore || data.vectorStore === '')) {
          onChangeVectorStore(stores.vector_stores[0]);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchVectorStores();
  }, [data.tool, data.vectorStore, onChangeVectorStore]);

  const handleTypeChange = useCallback((evt) => {
    onChangeType(evt);
  }, [onChangeType]);

  const handleToolChange = (selectedOptions) => {
    const selectedTools = selectedOptions ? selectedOptions.map(option => option.value) : [];

    onChangeTool(selectedTools);

    if (selectedTools.includes('rag') && (!data.vectorStore || data.vectorStore === '')) {
      if (vectorStores.length > 0) {
        onChangeVectorStore(vectorStores[0]);
      }
    }
  };

  const handleVectorStoreChange = useCallback((evt) => {
    const newVectorStore = evt.target.value;
    onChangeVectorStore(newVectorStore);
  }, [onChangeVectorStore]);

  const handleScenarioChange = useCallback((selectedScenario) => {
    const newScenario = selectedScenario.target.value;
    onChangeScenario(newScenario);
  }, [onChangeScenario]);

  const handleAddPath = useCallback(() => {
    setNewPathName('');
    setPathModalOpen(true);
  }, []);

  const handleDeletePath = useCallback((pathKey) => {
    onDeletePath(pathKey);
  }, [onDeletePath]);

  const renderIcon = (type) => {
    switch(type) {
      case 'START':
        return <FaPlay className={`text-[20px] mb-[5px] ${darkMode ? 'text-white' : 'text-zinc-700'}`} />;
      case 'STEP':
        return <FaRobot className={`text-[20px] mb-[5px] ${darkMode ? 'text-white' : 'text-zinc-700'}`} />;
      case 'CONDITION':
        return <TiFlowSwitch className={`text-[20px] mb-[5px] ${darkMode ? 'text-white' : 'text-zinc-700'}`} />;
      case 'SCENARIO':
        return <FaBookOpen className={`text-[20px] mb-[5px] ${darkMode ? 'text-white' : 'text-zinc-700'}`} />;
      case 'MESSAGE':
        return <MdMessage className={`text-[20px] mb-[5px] ${darkMode ? 'text-white' : 'text-zinc-700'}`} />;
      case 'STICKER':
        return <FaStickyNote className={`text-[20px] mb-[5px] ${darkMode ? 'text-yellow-300' : 'text-yellow-500'}`} />;
      default:
        return null;
    }
  };

  const customSelectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: darkMode ? '#2d2d2d' : '#ffffff',
      color: darkMode ? '#ffffff' : '#000000',
      borderColor: darkMode ? '#444444' : '#cccccc',
      minHeight: '38px',
      height: 'auto',
      '&:hover': {
        borderColor: darkMode ? '#666666' : '#999999',
      },
      boxShadow: 'none',
    }),
    valueContainer: (styles) => ({
      ...styles,
      display: 'flex',
      flexWrap: 'wrap',
      padding: '2px 8px',
      gap: '2px',
      maxWidth: '100%',
      overflow: 'visible',
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: darkMode ? '#3b3b3b' : '#eaeaea',
      margin: '2px 0',
      maxWidth: 'calc(100% - 4px)',
      flexShrink: 1,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '4px',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: darkMode ? '#ffffff' : '#000000',
      padding: '2px 6px',
      fontSize: '0.85rem',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: darkMode ? '#ffffff' : '#000000',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: darkMode ? '#555555' : '#cccccc',
        color: darkMode ? '#ffffff' : '#000000',
      },
      padding: '0 4px',
    }),
    container: (styles) => ({
      ...styles,
      width: '100%',
    }),
    input: (styles) => ({
      ...styles,
      maxWidth: '100%',
      color: darkMode ? '#ffffff' : '#000000',
      margin: 0,
      padding: 0,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: darkMode ? '#999999' : '#666666',
    }),
    menu: (styles) => ({
      ...styles,
      width: '100%',
      backgroundColor: darkMode ? '#2d2d2d' : '#ffffff',
      zIndex: 999,
    }),
    menuList: (styles) => ({
      ...styles,
      padding: '4px 0',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected 
        ? (darkMode ? '#4a4a4a' : '#e2e8f0')
        : isFocused 
          ? (darkMode ? '#3b3b3b' : '#f7fafc')
          : 'transparent',
      color: darkMode ? '#ffffff' : '#000000',
      cursor: 'pointer',
      ':active': {
        backgroundColor: darkMode ? '#4a4a4a' : '#e2e8f0',
      },
    }),
    clearIndicator: (styles) => ({
      ...styles,
      cursor: 'pointer',
      color: darkMode ? '#999999' : '#666666',
      ':hover': {
        color: darkMode ? '#ffffff' : '#000000',
      },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      cursor: 'pointer',
      color: darkMode ? '#999999' : '#666666',
      ':hover': {
        color: darkMode ? '#ffffff' : '#000000',
      },
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      backgroundColor: darkMode ? '#444444' : '#cccccc',
    }),
    noOptionsMessage: (styles) => ({
      ...styles,
      color: darkMode ? '#999999' : '#666666',
    }),
    loadingMessage: (styles) => ({
      ...styles,
      color: darkMode ? '#999999' : '#666666',
    }),
    group: (styles) => ({
      ...styles,
      padding: '0',
    }),
    groupHeading: (styles) => ({
      ...styles,
      color: darkMode ? '#999999' : '#666666',
      fontSize: '0.85rem',
      fontWeight: 'bold',
      marginBottom: '4px',
      padding: '8px',
    }),
  };

  const openModal = (pathKey = null) => {
    if (pathKey) {
      setSelectedPathKey(pathKey);
      setPathDescription(data.pathDescriptions[pathKey] || '');
    } else {
      setSelectedPathKey(null);
      setFullDescription(data.description || '');
    }
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleDescriptionChange = (e) => {
    if (data.type === 'STICKER') {
      // Обновляем заметку для стикера
      onChangeDescription({ target: { value: e.target.value } });
    } else {
      // Существующая логика для остальных типов
      if (selectedPathKey) {
        setPathDescription(e.target.value);
      } else {
        setFullDescription(e.target.value);
      }
    }
  };

  const saveDescription = () => {
    if (data.type === 'STICKER') {
      // Сохранение заметки для стикера
      onChangeDescription({ target: { value: data.note } });
    } else if (selectedPathKey) {
      const updatedPathDescriptions = {
        ...data.pathDescriptions,
        [selectedPathKey]: pathDescription,
      };

      const updatedDescription = Object.keys(updatedPathDescriptions).reduce(
        (desc, pathKey) => {
          const pathDesc = updatedPathDescriptions[pathKey];
          if (pathDesc) {
            return `${desc}\n${pathDesc} используй для этого путь: "${pathKey}".`;
          }
          return desc;
        },
        data.description.split('\n')[0] || ''
      );

      onChangeDescription({ target: { value: updatedDescription } });
      onUpdatePathDescription(selectedPathKey, pathDescription);
    } else {
      let finalDescription = fullDescription;
      onChangeDescription({ target: { value: finalDescription } });
    }
    closeModal();
  };

  const saveNewPath = () => {
    if (newPathName) {
      onAddPath(newPathName);
    }
    closePathModal();
  };

  const closePathModal = () => {
    setPathModalOpen(false);
  };

  const keywords = [
    { value: 'последнее', description: 'Использовать последнее сообщение из графа' },
    { value: 'история_внеш', description: 'Использовать всю историю внешнюю историюю сообщений' },
    { value: 'без_истории', description: 'Не использовать историю сообщений' },
    { value: 'послед_ассист_внеш', description: 'Использовать последнее сообщение ai ассистента из внешей истории сообщений' },
    { value: 'послед_польз_внеш', description: 'Использовать последнее сообщение пользователя из внешей истории сообщений' }
  ];

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [filteredKeywords, setFilteredKeywords] = useState(keywords);
  const textareaRef = useRef(null);



  const handleTextareaChange = (e) => {
    const value = e.target.value;
    const cursorPos = e.target.selectionStart;
    
    // Обновляем значение в текстовом поле
    handleDescriptionChange(e);
    
    // Проверяем наличие символа "/" перед курсором
    const textBeforeCursor = value.substring(0, cursorPos);
    const lastSlashIndex = textBeforeCursor.lastIndexOf('/');
    const textAfterLastSlash = textBeforeCursor.substring(lastSlashIndex + 1);
    
    // Показываем подсказки только если есть "/" и после него нет пробела
    if (lastSlashIndex !== -1 && !textAfterLastSlash.includes(' ')) {
      setShowSuggestions(true);
      // Фильтруем подсказки по введенному тексту после "/"
      const searchText = textAfterLastSlash.toLowerCase();
      setFilteredKeywords(
        keywords.filter(k => k.value.toLowerCase().includes(searchText))
      );
    } else {
      setShowSuggestions(false);
    }
  };

  const insertKeyword = (keyword) => {
    const textarea = textareaRef.current;
    const value = textarea.value;
    const cursorPos = textarea.selectionStart;
    
    // Находим позицию последнего "/"
    const textBeforeCursor = value.substring(0, cursorPos);
    const lastSlashIndex = textBeforeCursor.lastIndexOf('/');
    
    // Формируем новое значение
    const newValue = value.substring(0, lastSlashIndex) + '/' + keyword + value.substring(cursorPos);
  
    if (data.type === 'STICKER') {
      onChangeDescription({ target: { value: newValue } });
    } else {
      selectedPathKey ? setPathDescription(newValue) : setFullDescription(newValue);
    }
  
    setShowSuggestions(false);
  
    // Устанавливаем курсор после вставленного ключевого слова
    setTimeout(() => {
      textarea.focus();
      const newCursorPos = lastSlashIndex + keyword.length + 1;
      textarea.setSelectionRange(newCursorPos, newCursorPos);
    }, 0);
  };

  // Предопределенные цвета для стикера
  const colorOptions = [
    { value: '#FFFA75', label: 'Желтый' },
    { value: '#FF8A80', label: 'Красный' },
    { value: '#80D8FF', label: 'Синий' },
    { value: '#A7FFEB', label: 'Зеленый' },
    { value: '#FFD180', label: 'Оранжевый' },
    { value: '#EA80FC', label: 'Фиолетовый' },
  ];

  const handleColorChange = (color) => {
    onChangeColor(color);
    setColorPaletteOpen(false);
  };



  const modelOptions = [
    { value: 'gpt-4o', label: <span className='flex flex-row items-center'><FaRobot className="text-blue-500 mr-2 text-xs" />gpt-4o</span> },
    { value: 'gpt-4o-mini', label: <span className='flex flex-row items-center'><FaRobot className="text-green-500 mr-2 text-xs" />gpt-4o-mini</span> },
    { value: 'o1-mini', label: <span className='flex flex-row items-center'><FaRobot className="text-purple-500 mr-2 text-xs" />o1-mini</span> },
    { value: 'llama-3.1-saiga', label: <span className='flex flex-row items-center'><FaRobot className="text-orange-500 mr-2 text-xs" />llama-3.1-saiga</span> },
  ];

  // Add handler for model change
  const handleModelChange = (selectedOption) => {
    onChangeModel(selectedOption.value);
  };

  // Функция для автоматического изменения высоты textarea
  // const autoResizeTextarea = () => {
  //   const textarea = textareaRef.current;
  //   if (textarea) {
  //     textarea.style.height = 'auto'; // Сброс высоты
  //     textarea.style.height = `${textarea.scrollHeight}px`; // Установка новой высоты
  //   }
  // };

  // Автоматически изменяем высоту при монтировании и изменении данных
  // useEffect(() => {
  //   autoResizeTextarea();
  // }, [data.note, data.description, pathDescription]);

  return (
    <div
      className={`p-4 rounded-lg shadow-lg ${data.type === 'STICKER' ? 'sticker' : (darkMode ? 'bg-gray-800' : 'bg-white')} flex flex-col justify-between`}
      style={{ backgroundColor: data.type === 'STICKER' ? data.color : undefined }}
    >
      {data.type !== 'STICKER' && (
        <>
          {data.type !== 'START' && (
            <Handle
              type="target"
              position={Position.Left}
              isConnectable={isConnectable}
              style={{ left: '-5px', top: 'calc(50% - 5px)', background: darkMode ? 'white' : 'black' }}
            />
          )}
          {data.type === 'START' && (
            <Handle
              type="source"
              position={Position.Right}
              style={{ right: '-5px', top: 'calc(50% - 5px)', background: darkMode ? 'white' : 'black' }}
              isConnectable={isConnectable}
            />
          )}
          {data.type === 'CONDITION' && Object.keys(data.paths).map((pathKey, index) => (
            <Handle
              key={pathKey}
              type="source"
              position={Position.Right}
              id={pathKey}
              style={{
                top: `${index * 36 + 315 + (Object.keys(data.paths).length - 1)}px`,
                right: '-5px',
                background: darkMode ? 'white' : 'black'
              }}
              isConnectable={isConnectable}
            />
          ))}
          {data.type !== 'CONDITION' && (
            <Handle
              type="source"
              position={Position.Right}
              style={{ right: '-5px', top: 'calc(50% - 5px)', background: darkMode ? 'white' : 'black' }}
              isConnectable={isConnectable}
            />
          )}
        </>
      )}
      <div className="flex flex-col flex-grow ">
        <div className={`flex justify-center items-center mb-2 relative w-full rounded ${data.type !== 'STICKER' ? (darkMode ? 'bg-gray-900' : 'bg-gray-200') : ''}`}>
          {data.type !== 'STICKER' && (
            <>
              <div className='w-full h-full flex justify-center items-center'>
                <span className={`text-base-content justify-center mt-1`}>{renderIcon(data.type)}</span>
              </div>
              {/* Добавлен знак вопроса для подсказки */}
              <span className="tooltip absolute left-2 top-1/2 transform -translate-y-1/2" data-tip={infoTexts[data.type]}>
                <FaQuestionCircle className="text-gray-500 cursor-pointer" />
              </span>
            </>
          )}
        </div>

        {data.type === 'STICKER' ? (
          // Специфический рендеринг для стикера
          <div className="flex flex-col flex-grow p-2 w-full">
            <textarea
              className="textarea resize-none w-full mb-2 bg-transparent text-black overflow-hidden"
              value={data.note || ''}
              onChange={handleDescriptionChange}
              placeholder="Заметка..."
              ref={textareaRef}
              rows={1}
              style={{ minHeight: '50px', maxHeight: '300px', overflowY: 'auto' }}
            />
            <div className="flex items-center mt-2">
              <button
                className={`w-6 h-6 rounded-full cursor-pointer border ${data.color ? 'border-gray-300' : 'border-transparent'}`}
                style={{ backgroundColor: data.color || '#FFFFFF' }}
                onClick={() => setColorPaletteOpen(!isColorPaletteOpen)}
              />
              {isColorPaletteOpen && (
                <div className="flex space-x-2 bg-base-100 shadow-lg rounded-lg p-1 fixed mt-24 ">
                  {colorOptions.map(option => (
                    <div 
                      key={option.value} 
                      onClick={() => handleColorChange(option.value)} 
                      className={`w-6 h-6 rounded-full cursor-pointer border ${data.color === option.value ? 'border-black' : 'border-transparent'}`} 
                      style={{ backgroundColor: option.value }}>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ) : (
          // Существующий рендеринг для других типов узлов
          <>
            <div>
              <label htmlFor="type" className="block text-sm mb-1">Тип:</label>
              <select
                id="type"
                name="type"
                value={data.type}
                onChange={handleTypeChange}
                className="select select-bordered w-full"
              >
                <option value="START">СТАРТ</option>
                <option value="STEP">АГЕНТ</option>
                <option value="CONDITION">УСЛОВИЕ</option>
                {/* <option value="SCENARIO">СЦЕНАРИЙ</option> */}
                <option value="MESSAGE">СООБЩЕНИЕ</option>
                <option value="STICKER">СТИКЕР</option> {/* Новая опция */}
              </select>
            </div>
            {data.type !== 'START' && (
              <>
                {['STEP', 'CONDITION'].includes(data.type) && (
                  <div>
                    <label htmlFor="text" className="block text-sm mb-1 mt-4">Название:</label>
                    <input
                      id="text"
                      name="text"
                      value={data.name}
                      maxLength={50}
                      onChange={onChangeName}
                      className="input input-bordered w-full"
                    />
                  </div>
                )}
                {data.type === 'STEP' && (
        <>
          <div className='mb-2 w-[300px]'>
            <label htmlFor="model" className="block text-sm mb-1 mt-4">Модель ИИ:</label>
            <Select
              placeholder="Выберите модель"
              id="model"
              name="model"
              value={modelOptions.find(option => option.value === data.model)}
              onChange={handleModelChange}
              options={modelOptions}
              styles={customSelectStyles}
              className="nodrag"
            />
          </div>
                  <div className='mb-2 w-[300px]'>
                    <label htmlFor="tool" className="block text-sm mb-1 mt-4">Инструменты:</label>
                    <Select
  placeholder="Выберите инструмент"
  id="tool"
  name="tool"
  value={toolOptions.filter(option => data.tool.includes(option.value))}
  onChange={handleToolChange}
  options={toolOptions}
  isMulti
  styles={customSelectStyles}
  className="nodrag "
  maxMenuHeight={200}
  menuPlacement="auto"
  isClearable={false}
/>
                  </div>
                  </>
                )}
                {data.tool.includes('rag') && (
                  <div>
                    <label htmlFor="vectorStore" className='mt-2 block text-sm mb-1'>База документов:</label>
                    <select
                      id="vectorStore"
                      name="vectorStore"
                      value={data.vectorStore || ''}
                      onChange={handleVectorStoreChange}
                      className="select select-bordered w-full mb-2"
                    >
                      {loading ? (
                        <option value="">Загрузка...</option>
                      ) : error ? (
                        <option value="">Ошибка: {error}</option>
                      ) : (
                        vectorStores.map((store) => (
                          <option key={store} value={store}>{store}</option>
                        ))
                      )}
                    </select>
                  </div>
                
                )}
              
                {data.type === 'SCENARIO' && (
                  <div className='text-left justify-start flex items-center'>
                    <select
                      id="scenario_id"
                      name="scenario_id"
                      value={data.scenario_id || ''}
                      onChange={handleScenarioChange}
                      className="select select-bordered w-full "
                    >
                      {loading ? (
                        <option value="">Загрузка...</option>
                      ) : error ? (
                        <option value="">Ошибка: {error}</option>
                      ) : (
                        scenarios
                          .filter(scenario => scenario.file_name !== data.selectedScenario?.file_name)
                          .map((scenario) => (
                            <option
                              key={scenario.file_name}
                              value={scenario.file_name.replace(/\.json$/, '')}
                            >
                              {scenario.file_name.replace(/\.json$/, '')}
                            </option>
                          ))
                      )}
                    </select>
                  </div>
                )}
                {data.type === 'MESSAGE' && (
                  <div className='text-left justify-start flex items-center'>
                    <button className={`btn ${darkMode ? 'btn-secondary' : 'btn-primary'} mt-2 w-full`}
                      onClick={() => openModal()}
                    >
                      Добавить сообщение
                    </button>
                  </div>
                )}
                {['STEP', 'CONDITION'].includes(data.type) && (
                  <>
                    <button className={`btn ${darkMode ? 'btn-secondary' : 'btn-primary'} w-full mt-2 mb-2`}
                      onClick={() => openModal()}
                    >
                      Описание
                    </button>
                  </>
                )}
                {data.type === 'CONDITION' && (
                  <div>
                    <label className="block text-sm mb-1">Пути:</label>
                    {Object.keys(data.paths).map((pathKey, index) => (
                      <div key={pathKey} className="flex items-center mb-2">
                        <span className={`flex-1 rounded p-1 ${darkMode ? 'bg-gray-600 text-white' : 'bg-gray-300 text-black'}`}>{pathKey}</span>
                        <FaEdit
                          onClick={() => openModal(pathKey)}
                          className={`ml-2 text-2xl p-1 cursor-pointer rounded hover:bg-blue-700 ${darkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white'}`}
                        />
                        <button
                          onClick={() => handleDeletePath(pathKey)}
                          className={`ml-2 p-1 rounded hover:bg-red-700 ${darkMode ? 'bg-red-600 text-white' : 'bg-red-500 text-white'}`}
                        >
                          <FaTrash />
                        </button>
                      </div>
                    ))}
                    <button
                      onClick={handleAddPath}
                      className={`btn ${darkMode ? 'btn-secondary' : 'btn-primary'} w-full`}
                    >
                      <FaPlus className='text-center'/>
                    </button>
                  </div>
                )}
              </>
            )}
          </>
        )}


{isModalOpen && ReactDOM.createPortal(
  <div className="fixed inset-0 z-50">
    <div className="fixed inset-0 bg-black bg-opacity-50"></div>
    <div className="flex items-center justify-center min-h-screen p-4">
    <div className="modal-box max-w-3xl w-full bg-base-100 text-base-content flex flex-col">
  <div className="mb-4">
    <h3 className='text-lg font-medium text-center'>
      {selectedPathKey ? `Описание пути: ${selectedPathKey}` : 'Описание'}
    </h3>
    <p className="text-xs text-center text-base-content/70 mt-1">
      Используйте <kbd className="kbd kbd-sm">/</kbd> для вызова подсказок с командами
    </p>
  </div>
    
        <div className='flex-grow overflow-auto mb-4 relative'>
  <div className="relative w-full h-[400px]"> {/* Контейнер фиксированной высоты */}
    <textarea
      ref={textareaRef}
      className='textarea textarea-bordered w-full h-full bg-base-200'
      value={selectedPathKey ? pathDescription : fullDescription}
      onChange={handleTextareaChange}
      style={{ 
        resize: 'none',
        overflowY: 'auto'
      }}
    />
    {showSuggestions && (
      <div className="absolute top-2 left-2 right-2 bg-base-100 border border-base-300 rounded-lg shadow-xl z-10">
        <div className="p-2 border-b border-base-300 bg-base-200">
          <span className="text-sm font-medium">Доступные команды:</span>
        </div>
        <div className="max-h-[200px] overflow-y-auto">
          {filteredKeywords.map(keyword => (
            <div
              key={keyword.value}
              className="p-2 hover:bg-base-200 cursor-pointer border-b border-base-300 last:border-none"
              onClick={() => insertKeyword(keyword.value)}
            >
              <div className="flex items-start">
                <div className="flex-shrink-0 w-24">
                  <code className="text-primary font-mono">/{keyword.value}</code>
                </div>
                <div className="ml-2 text-sm text-base-content/70">
                  {keyword.description}
                </div>
              </div>
            </div>
          ))}
          {filteredKeywords.length === 0 && (
            <div className="p-2 text-center text-base-content/70">
              Нет совпадений
            </div>
          )}
        </div>
      </div>
    )}
  </div>
</div>
        <div className='modal-action mt-auto'>
          <button 
            onClick={closeModal} 
            className="btn btn-ghost"
          >
            Отмена
          </button>
          <button 
            onClick={saveDescription} 
            className="btn btn-primary"
          >
            Сохранить
          </button>
        </div>
      </div>
    </div>
  </div>,
  document.body
)}

        {isPathModalOpen && (
          <div className="modal modal-open">
            <div className="modal-box w-full h-full">
              <h3 className='text-xs font-semibold text-center mb-3'>Добавить новый путь</h3>
              <input className='input input-bordered w-full'
                value={newPathName}
                onChange={(e) => setNewPathName(e.target.value)}
                placeholder="Введите название"
              />
              <div className='flex flex-col justify-center gap-1 text-xs transition'>
                <button onClick={closePathModal} className={`btn ${darkMode ? 'btn-secondary' : 'btn-primary'}`}>
                  Отмена
                </button>
                <button onClick={saveNewPath} className={`btn btn-primary`}>
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {data.type !== 'STICKER' && (
        null
      )}

      {/* Специфические стили для стикера */}
      <style jsx>{`
        .sticker {
          background-color: ${data.color}; /* Динамический цвет стикера */
          border: 1px solid #f0e68c;
          box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
          width: 150px; /* Примерная ширина стикера */
          min-height: 100px; /* Минимальная высота стикера */
          max-width: 1000px; /* Максимальная ширина стикера */
          padding: 8px;
          border-radius: 8px;
          position: relative;
        }
      `}</style>
    </div>
  );
};

NodeLayout.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    tool: PropTypes.arrayOf(PropTypes.string),
    vectorStore: PropTypes.string,
    description: PropTypes.string,
    paths: PropTypes.object,
    pathDescriptions: PropTypes.object,
    theme: PropTypes.string.isRequired,
    scenario_id: PropTypes.string,
    note: PropTypes.string, // Новое свойство для "STICKER"
    color: PropTypes.string, // Новое свойство для "STICKER"
  }).isRequired,
  isConnectable: PropTypes.bool.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
  onChangeType: PropTypes.func.isRequired,
  onChangeTool: PropTypes.func.isRequired,
  onChangeVectorStore: PropTypes.func.isRequired,
  onAddPath: PropTypes.func.isRequired,
  onDeletePath: PropTypes.func.isRequired,
  onSaveNode: PropTypes.func.isRequired,
  onUpdatePathDescription: PropTypes.func.isRequired,
  onChangeScenario: PropTypes.func.isRequired,
  onChangeColor: PropTypes.func.isRequired, // Новая проп
  onChangeModel: PropTypes.func.isRequired,
};

export default React.memo(NodeLayout);