export const API_URL = 'https://admin.nikta.ai/llm';

export async function authenticatedFetch(url, options = {}) {
  const token = localStorage.getItem('authToken');
  if (!token) {
    throw new Error('No authentication token found');
  }
  
  const headers = {
    ...options.headers,
    'Authorization': `Bearer ${token}`
  };
  
  const response = await fetch(`${API_URL}${url}`, {
    ...options,
    headers
  });
  
  if (response.status === 401) {
    // Token is invalid or expired
    localStorage.removeItem('authToken');
    // Redirect to login page or handle as needed
    window.location.href = '/login';
    throw new Error('Authentication failed');
  }
  
  return response;
}