import { memo, useCallback, useState, useEffect } from 'react';
import NodeLayout from './NodeLayout';
import { useGraphManager } from './GraphManagerContext';

export const removePrevs = (nodes, nodeId) => {
  const nodeToRemovePrevs = nodes.find(node => node.id === nodeId);
  if (!nodeToRemovePrevs || !nodeToRemovePrevs.prevs) return nodes;

  nodeToRemovePrevs.prevs.forEach(prevId => {
    const prevNode = nodes.find(node => node.id === prevId);
    if (prevNode && prevNode.data.nexts) {
      prevNode.data.nexts = prevNode.data.nexts.filter(id => id !== nodeId);
    }
  });

  nodeToRemovePrevs.prevs = [];
  return nodes;
};

export const removeNexts = (nodes, nodeId) => {
  const nodeToRemoveNexts = nodes.find(node => node.id === nodeId);
  if (!nodeToRemoveNexts || !nodeToRemoveNexts.data.nexts) return nodes;

  nodeToRemoveNexts.data.nexts.forEach(nextId => {
    const nextNode = nodes.find(node => node.id === nextId);
    if (nextNode && nextNode.prevs) {
      nextNode.prevs = nextNode.prevs.filter(id => id !== nodeId);
    }
  });

  nodeToRemoveNexts.data.nexts = [];
  return nodes;
};

export const removePaths = (nodes, nodeId) => {
  const nodeToRemovePaths = nodes.find(node => node.id === nodeId);
  if (!nodeToRemovePaths) return nodes;

  Object.keys(nodeToRemovePaths.data.paths || {}).forEach(pathKey => {
    const nextId = nodeToRemovePaths.data.paths[pathKey];
    if (nextId) {
      const nextNode = nodes.find(node => node.id === nextId);
      if (nextNode) {
        nextNode.prevs = nextNode.prevs.filter(id => id !== nodeId);
      }
    }
  });

  nodeToRemovePaths.data.paths = {};
  return nodes;
};

function Node({ data, isConnectable, id }) {
  const { nodes, setNodes } = useGraphManager();
  const [nodeData, setNodeData] = useState(data);

  useEffect(() => {
    setNodeData(data);
  }, [data]);

  const updateNodeData = (updateFn) => {
    setNodes((nds) => {
      return nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              prevs: node.data.prevs || [], // инициализация prevs, если отсутствует
              nexts: node.data.nexts || [], // инициализация nexts, если отсутствует
              paths: node.data.paths || {}, // инициализация paths, если отсутствует
              pathDescriptions: node.data.pathDescriptions || {}, // инициализация pathDescriptions
              ...updateFn(node.data),
            },
          };
        }
        return node;
      });
    });
  };

  const onChangeName = useCallback((evt) => {
    updateNodeData((prevData) => ({ ...prevData, name: evt.target.value }));
  }, [id, setNodes]);

  const onChangeDescription = useCallback((evt) => {
    if (data.type === 'STICKER') {
      updateNodeData((prevData) => ({ ...prevData, note: evt.target.value }));
    } else {
      updateNodeData((prevData) => ({ ...prevData, description: evt.target.value }));
    }
  }, [id, setNodes, data.type]);

  const onChangeType = useCallback((evt) => {
    const newType = evt.target.value;
    
    // Проверка на наличие узла типа 'START'
    if (newType === 'START') {
      const existingStartNode = nodes.find(node => node.data.type === 'START' && node.id !== id);
      if (existingStartNode) {
        alert('Узел типа "START" уже существует. Удалите его, чтобы создать новый.');
        return;
      }
    }
  
    updateNodeData((prevData) => ({ ...prevData, type: newType }));
  }, [id, setNodes, nodes]);

  const onChangeTool = useCallback((tool) => {
    updateNodeData((prevData) => ({ ...prevData, tool }));
  }, [id, setNodes]);

  const onChangeScenario = useCallback((scenario_id) => {
    updateNodeData((prevData) => ({ ...prevData, scenario_id }));
  }, [id, setNodes]);

  const onChangeVectorStore = useCallback((vectorStore) => {
    setNodes((nodes) => {
      const updatedNodes = nodes.map((node) =>
        node.id === id
          ? { ...node, data: { ...node.data, vectorStore } }
          : node
      );
      console.log('Updated Nodes:', updatedNodes);
      return updatedNodes;
    });
  }, [id, setNodes]);
  
  const onAddPath = useCallback((pathKey) => {
    updateNodeData((prevData) => ({
      ...prevData,
      paths: { ...prevData.paths, [pathKey]: null },
      pathDescriptions: { ...prevData.pathDescriptions, [pathKey]: '' }, // Инициализируем пустое описание
    }));
  }, [id, setNodes]);
  
  const onDeletePath = useCallback((pathKey) => {
    updateNodeData((prevData) => {
      const { [pathKey]: _, ...remainingPaths } = prevData.paths;
      const { [pathKey]: __, ...remainingDescriptions } = prevData.pathDescriptions;
      return { 
        ...prevData, 
        paths: remainingPaths, 
        pathDescriptions: remainingDescriptions // Удаляем описание пути
      };
    });
  }, [id, setNodes]);

  const onUpdatePathDescription = useCallback((pathKey, description) => {
    updateNodeData((prevData) => ({
      ...prevData,
      pathDescriptions: {
        ...prevData.pathDescriptions,
        [pathKey]: description,
      },
    }));
  }, [id, setNodes]);

  const onChangeColor = useCallback((color) => { // Новая функция для изменения цвета
    updateNodeData((prevData) => ({ ...prevData, color }));
  }, [id, setNodes]);

  const onChangeModel = useCallback((model) => {
    updateNodeData((prevData) => ({ ...prevData, model }));
  }, [id, setNodes]);

  return (
    <NodeLayout
      data={nodeData}
      isConnectable={isConnectable}
      onChangeName={onChangeName}
      onChangeDescription={onChangeDescription}
      onChangeType={onChangeType}
      onChangeTool={onChangeTool}
      onChangeVectorStore={onChangeVectorStore} 
      onAddPath={onAddPath}
      onDeletePath={onDeletePath}
      onUpdatePathDescription={onUpdatePathDescription}
      onChangeScenario={onChangeScenario}
      onChangeColor={onChangeColor}
      onChangeModel={onChangeModel} // Добавляем новый проп
    />
  );
}

export const addNode = (nodes, setNodes, nodeIdCounter, setNodeIdCounter, newPosition) => {
  const newNode = {
    id: nodeIdCounter.toString(),
    type: 'textUpdater',
    data: { 
      name: `Node ${nodeIdCounter}`, 
      description: '', 
      type: 'STEP', 
      tool: [], 
      nexts: [], 
      paths: {}, 
      pathDescriptions: {}, // Инициализируем pathDescriptions
      vectorStore: '',
      scenario_id: '',
      note: '',                     // Инициализация свойства "note"
      color: '#FFFA75',             // Инициализация свойства "color"
    },
    position: newPosition,
    prevs: []
  };
  setNodes((nds) => nds.concat(newNode));
  setNodeIdCounter(nodeIdCounter + 1);
};

export const deleteNode = (nodes, setNodes, edges, setEdges, nodeId) => {
  let updatedNodes = [...nodes];
  const nodeToDelete = updatedNodes.find((node) => node.id === nodeId);
  if (!nodeToDelete) return;

  // Use helper functions to clean up references
  updatedNodes = removePrevs(updatedNodes, nodeId);
  updatedNodes = removeNexts(updatedNodes, nodeId);
  updatedNodes = removePaths(updatedNodes, nodeId);

  // Remove the node itself
  updatedNodes = updatedNodes.filter((node) => node.id !== nodeId);
  setNodes(updatedNodes);

  // Remove edges connected to this node
  setEdges((eds) => eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
};

export default memo(Node);