class NodeData {
  constructor({
    uniq_id = '',
    pos_x = 0,
    pos_y = 0,
    width = 200,
    height = 500,
    nexts = [],
    type = 'START',
    name = '',
    description = '',
    tool = [],
    paths = {},
    pathDescriptions = {}, 
    vectorStore = '', 
    scenario_id = '',
    note = '',          
    color = '#FFFA75',  
    model = '',         // Добавляем новое свойство для выбора модели ИИ
  }) {
    this.uniq_id = uniq_id;
    this.pos_x = pos_x;
    this.pos_y = pos_y;
    this.width = width;
    this.height = height;
    this.nexts = nexts;
    this.type = type;
    this.name = name;
    this.description = description;
    this.tool = tool;
    this.paths = paths;
    this.pathDescriptions = pathDescriptions; 
    this.vectorStore = vectorStore;
    this.scenario_id = scenario_id.replace(/\.json$/, '');
    this.note = note;
    this.color = color;
    this.model = model;
  }

  static fromReactFlowNode(node) {
    return new NodeData({
      uniq_id: node.id,
      pos_x: node.position.x,
      pos_y: node.position.y,
      width: node.width || node.data.width || 200,
      height: node.height || node.data.height || 400,
      nexts: node.data.nexts || [],
      type: node.data.type || 'STEP',
      name: node.data.name || '',
      description: node.data.description || '',
      tool: node.data.tool || [],
      paths: node.data.paths || {},
      pathDescriptions: node.data.pathDescriptions || {},
      vectorStore: node.data.vectorStore || '', 
      scenario_id: (node.data.scenario_id || '').replace(/\.json$/, ''),
      note: node.data.note || '',          
      color: node.data.color || '#FFFA75',
      model: node.data.model || '',        // Добавляем обработку модели
    });
  }

  static fromDict(data) {
    return new NodeData({
      uniq_id: data.uniq_id,
      pos_x: data.pos_x,
      pos_y: data.pos_y,
      width: data.width,
      height: data.height,
      nexts: data.nexts,
      type: data.type,
      name: data.name,
      description: data.description,
      tool: data.tool || [], 
      paths: data.paths,
      pathDescriptions: data.pathDescriptions || {}, 
      vectorStore: data.vectorStore || '', 
      scenario_id: (data.scenario_id || '').replace(/\.json$/, ''),
      note: data.note || '',          
      color: data.color || '#FFFA75',
      model: data.model || '',        // Добавляем обработку модели
    });
  }

  toReactFlowNode() {
    return {
      id: this.uniq_id,
      type: 'textUpdater',
      data: {
        name: this.name,
        description: this.description,
        nexts: this.nexts,
        type: this.type,
        tool: this.tool,
        paths: this.paths,
        pathDescriptions: this.pathDescriptions, 
        vectorStore: this.vectorStore, 
        scenario_id: this.scenario_id,
        note: this.note,          
        color: this.color,        
        model: this.model,        // Добавляем модель в данные узла
      },
      position: { x: this.pos_x, y: this.pos_y },
      width: this.width,
      height: this.height,
    };
  }

  toDict() {
    const {
      uniq_id, pos_x, pos_y, width, height,
      nexts, type, name, description,
      tool, paths, pathDescriptions, vectorStore, scenario_id,
      note, color, model,           // Добавляем model в деструктуризацию
    } = this;

    return {
      uniq_id,
      pos_x,
      pos_y,
      width,
      height,
      nexts,
      type,
      name,
      description,
      tool,
      paths,
      pathDescriptions, 
      vectorStore, 
      scenario_id,
      note,                 
      color,                
      model,                // Добавляем model в возвращаемый объект
    };
  }
}

export default NodeData;