import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FaFileUpload, FaTrash, FaFilePdf, FaFileWord, FaFileAlt, FaSpinner, FaFileExcel } from 'react-icons/fa';
import { authenticatedFetch } from './config';
import { useTheme } from './Canvas';

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
        <button className="absolute top-0 right-0 p-4" onClick={onClose}>✕</button>
        {children}
      </div>
    </div>,
    document.body
  );
}

function FileTransmit({ onUploadComplete }) {
  const { darkMode } = useTheme();
  const fileInputRef = useRef();
  const [vectorStoreName, setVectorStoreName] = useState('');
  const [modelType, setModelType] = useState('gpt-4o');
  const [openAIApiKey, setOpenAIApiKey] = useState('');
  const [apiKeys, setApiKeys] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const allowedFileTypes = ['docx', 'txt', 'pdf', 'xlsx'];

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const response = await authenticatedFetch('/api-keys');
        const data = await response.json();
        setApiKeys(data.keys);
      } catch (error) {
        console.error('Error fetching API keys:', error);
      }
    };

    fetchApiKeys();
  }, []);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const validFiles = Array.from(files).filter(file => {
      const extension = file.name.split('.').pop().toLowerCase();
      return allowedFileTypes.includes(extension);
    });

    if (validFiles.length !== files.length) {
      alert('Можно загружать только файлы с расширениями .docx, .txt, .pdf');
    }

    if (validFiles.length > 0) {
      setSelectedFiles(prevFiles => [...prevFiles, ...validFiles]);
    }
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return <FaFilePdf className="text-red-500" />;
      case 'doc':
      case 'docx':
        return <FaFileWord className="text-blue-500" />;
      case 'txt':
        return <FaFileAlt className="text-gray-500" />;
      case 'xlsx':
        return <FaFileExcel className="text-green-500" />;
      default:
        return <FaFileAlt className="text-gray-500" />;
    }
  };

  const handleSubmit = async () => {
    if (selectedFiles.length > 0 && vectorStoreName && modelType) {
      const formData = new FormData();
      selectedFiles.forEach(file => formData.append('files', file));
      formData.append('vectorStoreName', vectorStoreName);
      formData.append('modelType', modelType);
      if (modelType.startsWith('gpt') && openAIApiKey) {
        formData.append('openAIApiKey', openAIApiKey);
      }

      setLoading(true);

      try {
        const response = await authenticatedFetch('/upload', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          setSuccessModalIsOpen(true);
          if (onUploadComplete) {
            onUploadComplete();
          }
          setSelectedFiles([]);
        } else {
          const errorData = await response.json();
          alert('Ошибка: ' + errorData.error);
        }
      } catch (error) {
        alert('Ошибка: ' + error.message);
      } finally {
        setLoading(false);
      }
    } else {
      alert('Пожалуйста, заполните все поля');
    }
  };

  return (
    <div>
      <div className="relative group flex items-center">
        <button onClick={() => setModalIsOpen(true)} className="btn btn-ghost btn-circle">
          <FaFileUpload size={17} />
        </button>
        <div className="tooltip tooltip-right" data-tip="Загрузить файлы">
          <span className="sr-only">Загрузить файлы</span>
        </div>
      </div>

      {/* Основное модальное окно */}
      <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        <h3 className="font-bold text-lg mb-4">Загрузка файлов</h3>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileChange}
          multiple
        />
        <input
          type="text"
          placeholder="Название"
          value={vectorStoreName}
          onChange={(e) => setVectorStoreName(e.target.value)}
          className="input input-bordered w-full mb-4"
        />
        <select
          className="select select-bordered w-full mb-4"
          value={modelType}
          onChange={(e) => setModelType(e.target.value)}
        >
          <option value="gpt-4o">gpt-4o</option>
          <option value="gpt-4o-mini">gpt-4o-mini</option>
          <option value="llama-3.1-saiga">llama-3.1-saiga</option>
        </select>
        {modelType.startsWith('gpt') && (
          <select
            className="select select-bordered w-full mb-4"
            value={openAIApiKey}
            onChange={(e) => setOpenAIApiKey(e.target.value)}
          >
            <option value="">Выберите API ключ</option>
            {apiKeys.map(key => (
              <option key={key.id} value={key.key}>
                {key.name}
              </option>
            ))}
          </select>
        )}

        {/* Отображение выбранных файлов */}
        <ul className="mb-4 max-h-40 overflow-y-auto">
          {selectedFiles.map((file, index) => (
            <li key={index} className="flex justify-between items-center mb-2">
              <span className="flex items-center">
                {getFileIcon(file.name)}
                <span className="ml-2">{file.name}</span>
              </span>
              <button onClick={() => handleRemoveFile(index)} className="btn btn-ghost btn-circle btn-xs">
                <FaTrash />
              </button>
            </li>
          ))}
        </ul>

        <div className="flex flex-col gap-3">
          <button
            onClick={handleUploadClick}
            className="btn btn-primary"
          >
            Выбрать файлы
          </button>
          <button
            onClick={handleSubmit}
            className="btn btn-success"
            disabled={loading}
          >
            {loading ? <FaSpinner className="animate-spin mr-2" /> : null}
            Загрузить
          </button>
          <button onClick={() => setModalIsOpen(false)} className="btn">Отмена</button>
        </div>
      </Modal>

      {/* Модальное окно для успешной загрузки */}
      <Modal isOpen={successModalIsOpen} onClose={() => setSuccessModalIsOpen(false)}>
        <h3 className="font-bold text-lg mb-4">База файлов успешно загружена 🥳</h3>
        <div className="modal-action">
          <button onClick={() => setSuccessModalIsOpen(false)} className="btn btn-primary">Закрыть</button>
        </div>
      </Modal>
    </div>
  );
}

export default FileTransmit;