import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  FaPaperPlane, FaUser, FaRobot, FaRegTrashAlt, 
  FaClipboardList, FaKey, FaPlus, FaTimes, FaInfoCircle 
} from 'react-icons/fa';
import { authenticatedFetch } from './config';
import ReactDOM from 'react-dom';

function RunWindow({ onClose, scenarioId }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [running, setRunning] = useState(false);
  const [llmModel, setLlmModel] = useState('gpt-4o-mini');
  const [openaiApiKey, setOpenaiApiKey] = useState('');
  const [responseTime, setResponseTime] = useState(null);
  const [timer, setTimer] = useState(0);
  const intervalRef = useRef(null);
  const controllerRef = useRef(null);
  const [isLogsOpen, setLogsOpen] = useState(false);
  const [logs, setLogs] = useState('');
  const [tokens, setTokens] = useState(0);
  const [step, setStep] = useState('');
  const [stage, setStage] = useState('');
  const [messageRole, setMessageRole] = useState('human');
  const [apiKeys, setApiKeys] = useState([]);
  const [showKeyInput, setShowKeyInput] = useState(false);
  const [newKeyName, setNewKeyName] = useState('');
  const [newKeyDescription, setNewKeyDescription] = useState('');
  const messagesEndRef = useRef(null);
  
  const [clientData, setClientData] = useState(() => {
    const savedData = localStorage.getItem(`clientData_${scenarioId}`);
    return savedData ? JSON.parse(savedData) : {};
  });

  const [clientDataInfo, setClientDataInfo] = useState(() => {
    const savedInfo = localStorage.getItem(`clientDataInfo_${scenarioId}`);
    if (savedInfo) {
      return JSON.parse(savedInfo);
    }  
  });

  const [showClientData, setShowClientData] = useState(false);
  const [newClientDataKey, setNewClientDataKey] = useState('');
  const [newClientDataValue, setNewClientDataValue] = useState('');
  const [newClientDataTitle, setNewClientDataTitle] = useState('');
  const [newClientDataDescription, setNewClientDataDescription] = useState('');

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    fetchApiKeys();
  }, []);

  useEffect(() => {
    // Save clientData and clientDataInfo to localStorage whenever they change
    localStorage.setItem(`clientData_${scenarioId}`, JSON.stringify(clientData));
    localStorage.setItem(`clientDataInfo_${scenarioId}`, JSON.stringify(clientDataInfo));
  }, [clientData, clientDataInfo, scenarioId]);

  const fetchApiKeys = async () => {
    try {
      const response = await authenticatedFetch('/api-keys');
      const data = await response.json();
      setApiKeys(data.keys);
      if (data.keys.length > 0) {
        console.log('Setting API key:', data.keys[0].key);
        setOpenaiApiKey(data.keys[0].key);
      }
    } catch (error) {
      console.error('Error fetching API keys:', error);
    }
  };

  const handleSaveKey = async () => {
    if (!openaiApiKey || !newKeyName) return;
    
    try {
      const response = await authenticatedFetch('/api-keys', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: newKeyName,
          key: openaiApiKey,
          description: newKeyDescription // Included description
        })
      });
      
      if (response.ok) {
        await fetchApiKeys();
        setShowKeyInput(false);
        setNewKeyName('');
        setNewKeyDescription('');
        setOpenaiApiKey('');
      }
    } catch (error) {
      console.error('Error saving API key:', error);
    }
  };

  const handleDeleteKey = async (keyId) => {
    try {
      const response = await authenticatedFetch(`/api-keys/${keyId}`, {
        method: 'DELETE'
      });
      
      if (response.ok) {
        await fetchApiKeys();
      }
    } catch (error) {
      console.error('Error deleting API key:', error);
    }
  };

  const handleLogsModal = () => {
    setLogsOpen(true);
  };

  const clearMessages = () => {
    setMessages([]);
    setResponseTime(null);
    setTimer(0);
    setTokens(0);
    setStep('');
    setStage('');
    setLogs('');
  };

  useEffect(() => {
    if (running) {
      intervalRef.current = setInterval(() => {
        setTimer(prevTimer => prevTimer + 0.1);
      }, 100);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [running]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;
  
    const userMessage = { role: messageRole, content: newMessage.trim() };
    const updatedMessages = [...messages, userMessage];
  
    setMessages(updatedMessages);
    setNewMessage('');
  
    if (messageRole === 'assistant') {
      return;
    }
  
    setRunning(true);
    setTimer(0);
    setResponseTime(null);
    const startTime = Date.now();
  
    const state = {
      messages: updatedMessages.slice(-20),
    };
  
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;
  
    try {
      console.log('Sending request with key:', openaiApiKey);
      const response = await authenticatedFetch('/run', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          scenario_id: scenarioId,
          state,
          llm_model: llmModel,
          openai_api_key: openaiApiKey,
          channel_id: '6',
          dialog_id: '28',
          client_data: clientData, // Send as object
          client_data_info: clientDataInfo, // Include client_data_info
        }),
        signal: controller.signal,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const llmResponse = await response.json();
      console.log('Received response:', llmResponse);
      
      if (llmResponse.error) {
        console.error("Error from server:", llmResponse.error);
        setMessages(prev => [...prev, { role: 'assistant', content: `Error: ${llmResponse.error}` }]);
      } else {
        const llmMessageContent = llmResponse.result;
        console.log('LLM message content:', llmMessageContent);
        const llmMessage = { role: 'assistant', content: llmMessageContent };
  
        setMessages(prev => [...prev, llmMessage]);
        const endTime = Date.now();
        setResponseTime(((endTime - startTime) / 1000).toFixed(2));
  
        if (llmResponse.logs) {
          console.log('Logs:', llmResponse.logs);
          setLogs(formatLogs(llmResponse.logs));
        }
  
        if (llmResponse.last_step) {
          setStep(llmResponse.last_step);
        }
  
        if (llmResponse.last_stage) {
          setStage(llmResponse.last_stage);
        }
        
        if (llmResponse.tokens !== undefined) {
          setTokens(llmResponse.tokens);
        }
      }
    } catch (error) {
      console.error('Error in handleSendMessage:', error);
      setMessages(prev => [...prev, { 
        role: 'assistant', 
        content: `Error: ${error.message}. Please check your API key and try again.` 
      }]);
    } finally {
      setRunning(false);
    }
  };

  const formatLogs = (logs) => {
    if (typeof logs === 'string') {
      return logs;
    }
    return JSON.stringify(logs, null, 2);
  };

  const renderModelSelector = () => (
    <div className="bg-base-200 rounded-lg p-4">
      <label className="label">
        <div className="flex items-center gap-2">
          <span className="label-text font-medium">Основная модель</span>
          <div className="tooltip tooltip-bottom" data-tip="Эта модель будет использоваться по умолчанию. Если у отдельных агентов выбрана своя модель - будет использована она">
            <FaInfoCircle className="text-base-content/60 hover:text-primary transition-colors cursor-help" />
          </div>
        </div>
      </label>
      <select
        value={llmModel}
        onChange={(e) => setLlmModel(e.target.value)}
        className="select select-bordered w-full bg-base-100"
      >
        <option value="gpt-4o">GPT-4o</option>
        <option value="gpt-4o-mini">GPT-4o-mini</option>
        <option value="o1-mini">O1-mini</option>
        <option value="llama-3.1-saiga">Llama 3.1 Saiga</option>
        <option value="gemma-27b">Gemma 27B</option>
      </select>
    </div>
  );

  const renderApiKeySection = () => (
    <div className="bg-base-200 rounded-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-2">
          <FaKey className="text-primary" />
          <span className="font-medium">API Ключи</span>
        </div>
        <button 
          onClick={() => setShowKeyInput(true)}
          className="btn btn-primary btn-sm gap-2"
        >
          <FaPlus /> Добавить
        </button>
      </div>

      {showKeyInput ? (
        <div className="space-y-3 bg-base-100 p-4 rounded-lg">
          <div>
            <label className="label">
              <span className="label-text">Название ключа</span>
            </label>
            <input
              type="text"
              placeholder="Например: Основной ключ"
              value={newKeyName}
              onChange={(e) => setNewKeyName(e.target.value)}
              className="input input-bordered w-full"
            />
          </div>
          <div>
            <label className="label">
              <span className="label-text">Описание ключа</span>
            </label>
            <input
              type="text"
              placeholder="Описание ключа"
              value={newKeyDescription}
              onChange={(e) => setNewKeyDescription(e.target.value)}
              className="input input-bordered w-full"
            />
          </div>
          <div>
            <label className="label">
              <span className="label-text">OpenAI API Key</span>
            </label>
            <input
              type="password"
              placeholder="sk-..."
              value={openaiApiKey}
              onChange={(e) => setOpenaiApiKey(e.target.value)}
              className="input input-bordered w-full font-mono"
            />
          </div>
          <div className="flex space-x-2 pt-2">
            <button 
              onClick={handleSaveKey}
              className="btn btn-primary flex-1 gap-2"
              disabled={!newKeyName || !openaiApiKey}
            >
              <FaKey /> Сохранить
            </button>
            <button 
              onClick={() => {
                setShowKeyInput(false);
                setNewKeyName('');
                setNewKeyDescription('');
                setOpenaiApiKey('');
              }}
              className="btn btn-ghost flex-1 gap-2"
            >
              <FaTimes /> Отмена
            </button>
          </div>
        </div>
      ) : (
        <div className="max-h-[300px] overflow-y-auto space-y-2 pr-2">
          {apiKeys.length > 0 ? (
            apiKeys.map((key) => (
              <div 
                key={key.id} 
                className={`flex items-center justify-between p-3 rounded-lg transition-colors
                  ${openaiApiKey === key.key ? 'bg-primary/10 border border-primary' : 'bg-base-100'}`}
              >
                <div>
                  <div className="font-medium">{key.name}</div>
                  <div className="text-sm opacity-70 font-mono">{key.masked_key}</div>
                  <div className="text-xs opacity-50">{key.description}</div>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => setOpenaiApiKey(key.key)}
                    className={`btn btn-sm ${openaiApiKey === key.key ? 'btn-primary' : 'btn-ghost'}`}
                  >
                    {openaiApiKey === key.key ? 'Выбран' : 'Выбрать'}
                  </button>
                  <button 
                    onClick={() => handleDeleteKey(key.id)}
                    className="btn btn-sm btn-ghost text-error"
                  >
                    <FaRegTrashAlt />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-6 text-base-content/60 bg-base-100 rounded-lg">
              <FaKey className="mx-auto mb-2 opacity-40" />
              <p>Нет сохраненных ключей</p>
            </div>
          )}
        </div>
      )}
    </div>
  );

  const renderClientDataSection = () => (
    <div className="bg-base-200 rounded-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-2">
          <FaUser className="text-primary" />
          <span className="font-medium">Данные клиента</span>
        </div>
        <button 
          onClick={() => setShowClientData(!showClientData)}
          className="btn btn-primary btn-sm gap-2"
        >
          {showClientData ? <FaTimes /> : <FaPlus />}
        </button>
      </div>

      {showClientData && (
        <div className="space-y-3 bg-base-100 p-4 rounded-lg">
          <div className="grid grid-cols-2 gap-2">
            <div>
              <label className="label">
                <span className="label-text">Ключ</span>
              </label>
              <input
                type="text"
                placeholder="Например: phone"
                value={newClientDataKey}
                onChange={(e) => setNewClientDataKey(e.target.value)}
                className="input input-bordered w-full"
              />
            </div>
            <div>
              <label className="label">
                <span className="label-text">Значение</span>
              </label>
              <input
                type="text"
                placeholder="Значение"
                value={newClientDataValue}
                onChange={(e) => setNewClientDataValue(e.target.value)}
                className="input input-bordered w-full"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <label className="label">
                <span className="label-text">Заголовок</span>
              </label>
              <input
                type="text"
                placeholder="Человекочитаемое название"
                value={newClientDataTitle}
                onChange={(e) => setNewClientDataTitle(e.target.value)}
                className="input input-bordered w-full"
              />
            </div>
            <div>
              <label className="label">
                <span className="label-text">Описание</span>
              </label>
              <input
                type="text"
                placeholder="Описание поля"
                value={newClientDataDescription}
                onChange={(e) => setNewClientDataDescription(e.target.value)}
                className="input input-bordered w-full"
              />
            </div>
          </div>
          <button 
            onClick={handleAddClientData}
            className="btn btn-primary w-full"
            disabled={!newClientDataKey.trim() || !newClientDataTitle.trim()}
          >
            Добавить
          </button>
        </div>
      )}

      <div className="mt-4 space-y-2">
        {Object.entries(clientData).map(([key, value]) => (
          <div 
            key={key}
            className="flex items-center justify-between p-2 bg-base-100 rounded-lg"
          >
            <div>
              <div className="font-medium">{clientDataInfo[key]?.title || key}</div>
              <div className="text-sm opacity-70">{value}</div>
            </div>
            <button 
              onClick={() => handleRemoveClientData(key)}
              className="btn btn-ghost btn-sm text-error"
            >
              <FaRegTrashAlt />
            </button>
          </div>
        ))}
      </div>
    </div>
  );

  const renderStats = () => (
    <div className="flex space-x-2">
      <button 
        className="btn btn-outline btn-secondary btn-sm flex-1 gap-2" 
        onClick={handleLogsModal}
      >
        <FaClipboardList /> Логи
      </button>
      <button 
        className="btn btn-outline btn-error btn-sm flex-1 gap-2" 
        onClick={clearMessages}
      >
        <FaRegTrashAlt /> Очистить
      </button>
    </div>
  );

  const renderChatMessages = () => (
    <div className="flex-1 overflow-hidden flex flex-col">
      <div className="flex-1 p-4 overflow-y-auto space-y-4">
        {messages.length === 0 ? (
          <div className="h-full flex items-center justify-center text-base-content/60">
            <div className="text-center">
              <FaRobot className="mx-auto mb-2 text-3xl" />
              <p>Начните диалог</p>
            </div>
          </div>
        ) : (
          <>
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`chat ${msg.role === 'human' ? 'chat-end' : 'chat-start'}`}
              >
                <div className={`chat-bubble ${
                  msg.role === 'human' ? 'chat-bubble-primary' : 'chat-bubble-secondary'
                } shadow-lg`}>
                  <div className="chat-header mb-1 flex items-center gap-1">
                    {msg.role === 'human' ? <FaUser /> : <FaRobot />}
                    {msg.role === 'human' ? 'Вы' : 'Ассистент'}
                  </div>
                  <div className="whitespace-pre-wrap">{msg.content}</div>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </>
        )}
      </div>
    </div>
  );

  const handleAddClientData = () => {
    if (!newClientDataKey.trim() || !newClientDataTitle.trim()) return;
    
    setClientData(prev => {
      const newData = {
        ...prev,
        [newClientDataKey]: newClientDataValue
      };
      return newData;
    });

    // Add corresponding info
    setClientDataInfo(prev => ({
      ...prev,
      [newClientDataKey]: {
        name: newClientDataKey,
        type: typeof newClientDataValue === 'object' ? 'jsonb' : typeof newClientDataValue,
        title: newClientDataTitle,
        description: newClientDataDescription || `Описание поля ${newClientDataKey}`
      }
    }));

    setNewClientDataKey('');
    setNewClientDataValue('');
    setNewClientDataTitle('');
    setNewClientDataDescription('');
  };

  const handleRemoveClientData = (key) => {
    setClientData(prev => {
      const newData = { ...prev };
      delete newData[key];
      return newData;
    });

    setClientDataInfo(prev => {
      const newInfo = { ...prev };
      delete newInfo[key];
      return newInfo;
    });
  };

  // Сохранение clientData и clientDataInfo в localStorage при изменениях
  // (Handled in useEffect above)

  // Очистка временных данных при размонтировании компонента
  useEffect(() => {
    return () => {
      setMessages([]);
      setNewMessage('');
      setRunning(false);
      setResponseTime(null);
      setTimer(0);
      setTokens(0);
      setStep('');
      setStage('');
      setLogs('');
      // clientData и clientDataInfo сохраняются в localStorage, поэтому не очищаем их
      clearInterval(intervalRef.current);
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, []);

  const renderInputArea = () => (
    <div className="p-4 border-t border-base-300">
      <div className="flex items-center space-x-2">
        <button
          onClick={() => setMessageRole('human')}
          className={`btn btn-circle ${messageRole === 'human' ? 'btn-primary' : 'btn-ghost'}`}
        >
          <FaUser />
        </button>
        <button
          onClick={() => setMessageRole('assistant')}
          className={`btn btn-circle ${messageRole === 'assistant' ? 'btn-primary' : 'btn-ghost'}`}
        >
          <FaRobot />
        </button>
        <input
          type="text"
          className="input input-bordered flex-1"
          placeholder="Введите сообщение..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button
          onClick={handleSendMessage}
          disabled={running || !newMessage.trim()}
          className="btn btn-circle btn-primary"
        >
          <FaPaperPlane />
        </button>
      </div>
      <div className="h-6 text-sm text-center mt-2 text-base-content/60">
        {(running || responseTime || tokens > 0) && (
          <>
            {running && `Время ответа: ${timer.toFixed(1)} сек.`}
            {!running && responseTime && `Время ответа: ${responseTime} сек.`}
            {tokens > 0 && ` | Токенов: ${tokens}`}
          </>
        )}
      </div>
    </div>
  );

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.2 }}
      className="fixed inset-0 z-50 flex items-center justify-center p-4"
    >
      <div className="fixed inset-0 bg-black/50" onClick={onClose}></div>
      
      <div className="relative flex flex-col bg-base-100 rounded-lg shadow-2xl w-full max-w-5xl h-[80vh] z-10">
        <div className="flex justify-between items-center p-4 border-b border-base-300">
          <h2 className="text-xl font-semibold flex items-center gap-2">
            <FaRobot className="text-primary" />
            Чатбот
          </h2>
          <button 
            onClick={onClose} 
            className="btn btn-ghost btn-sm btn-circle text-error"
          >
            <FaTimes />
          </button>
        </div>

        <div className="flex flex-1 min-h-0">
          <div className="w-96 p-4 border-r border-base-300 space-y-4 overflow-y-auto">
            {renderModelSelector()}
            {llmModel.includes('gpt') && renderApiKeySection()}
            {renderClientDataSection()}
            {renderStats()}
          </div>

          <div className="flex-1 flex flex-col min-h-0">
            {renderChatMessages()}
            {renderInputArea()}
          </div>
        </div>
      </div>

      {isLogsOpen && ReactDOM.createPortal(
        <div className="fixed inset-0 z-[60] flex items-center justify-center p-4">
          <div className="fixed inset-0 bg-black/50" onClick={() => setLogsOpen(false)}></div>
          <div className="relative bg-base-100 rounded-lg shadow-2xl w-full max-w-3xl max-h-[80vh] overflow-hidden z-10">
            <div className="p-4 border-b border-base-300">
              <h3 className="text-lg font-semibold">Просмотр логов</h3>
            </div>
            
            <div className="p-4 space-y-4">
              <div className="bg-base-200 rounded-lg p-4 overflow-auto max-h-[50vh]">
                {logs ? (
                  <pre className="text-xs whitespace-pre-wrap break-words font-mono bg-gray-100 p-2 rounded-md max-h-[40vh] overflow-y-auto">
                    {logs}
                  </pre>
                ) : (
                  <p className="text-center text-base-content/60">Логи отсутствуют</p>
                )}
              </div>

              <div className="grid grid-cols-3 gap-4">
                <div className="stat bg-base-200 rounded-lg p-3">
                  <div className="stat-title">Токены</div>
                  <div className="stat-value text-lg">{tokens}</div>
                  <div className="stat-desc">За последний запрос</div>
                </div>
                <div className="stat bg-base-200 rounded-lg p-3">
                  <div className="stat-title">Шаг</div>
                  <div className="stat-value text-lg">{step || 'N/A'}</div>
                  <div className="stat-desc">Последний шаг</div>
                </div>
                <div className="stat bg-base-200 rounded-lg p-3">
                  <div className="stat-title">Сценарий</div>
                  <div className="stat-value text-lg">{stage || 'N/A'}</div>
                  <div className="stat-desc">Последний сценарий</div>
                </div>
              </div>
            </div>

            <div className="p-4 border-t border-base-300">
              <button 
                className="btn btn-ghost"
                onClick={() => setLogsOpen(false)}
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>,
        document.body
      )}
    </motion.div>
  );
}

export default RunWindow;